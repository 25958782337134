import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _68847faa = () => interopDefault(import('../pages/redirect/index.vue' /* webpackChunkName: "pages/redirect/index" */))
const _f1214044 = () => interopDefault(import('../pages/spinner.vue' /* webpackChunkName: "pages/spinner" */))
const _4212c3a9 = () => interopDefault(import('../pages/api/reservation/payout/tapngo.vue' /* webpackChunkName: "pages/api/reservation/payout/tapngo" */))
const _1c39d957 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2edc5fd3 = () => interopDefault(import('../pages/api/reservation/payout/_provider/fail.vue' /* webpackChunkName: "pages/api/reservation/payout/_provider/fail" */))
const _1c2cb2de = () => interopDefault(import('../pages/api/reservation/payout/_provider/success.vue' /* webpackChunkName: "pages/api/reservation/payout/_provider/success" */))
const _6cc2c4a4 = () => interopDefault(import('../pages/api/reservation/payout/_provider/web/fail.vue' /* webpackChunkName: "pages/api/reservation/payout/_provider/web/fail" */))
const _e36e39ba = () => interopDefault(import('../pages/api/reservation/payout/_provider/web/success.vue' /* webpackChunkName: "pages/api/reservation/payout/_provider/web/success" */))
const _e4626d4a = () => interopDefault(import('../pages/_region/index.vue' /* webpackChunkName: "pages/_region/index" */))
const _e2045576 = () => interopDefault(import('../pages/_region/_locale/index.vue' /* webpackChunkName: "pages/_region/_locale/index" */))
const _6f45fa80 = () => interopDefault(import('../pages/_region/_locale/about.vue' /* webpackChunkName: "pages/_region/_locale/about" */))
const _890513c4 = () => interopDefault(import('../pages/_region/_locale/airport-hotels.vue' /* webpackChunkName: "pages/_region/_locale/airport-hotels" */))
const _7281e1d4 = () => interopDefault(import('../pages/_region/_locale/app.vue' /* webpackChunkName: "pages/_region/_locale/app" */))
const _1d4661f8 = () => interopDefault(import('../pages/_region/_locale/best-price-guarantee.vue' /* webpackChunkName: "pages/_region/_locale/best-price-guarantee" */))
const _70a55242 = () => interopDefault(import('../pages/_region/_locale/blog/index.vue' /* webpackChunkName: "pages/_region/_locale/blog/index" */))
const _31f1f08c = () => interopDefault(import('../pages/_region/_locale/budget-hotels.vue' /* webpackChunkName: "pages/_region/_locale/budget-hotels" */))
const _ea794e84 = () => interopDefault(import('../pages/_region/_locale/completed.vue' /* webpackChunkName: "pages/_region/_locale/completed" */))
const _8fa3a25a = () => interopDefault(import('../pages/_region/_locale/contact.vue' /* webpackChunkName: "pages/_region/_locale/contact" */))
const _1e7d039a = () => interopDefault(import('../pages/_region/_locale/day-use-hotels.vue' /* webpackChunkName: "pages/_region/_locale/day-use-hotels" */))
const _2386a801 = () => interopDefault(import('../pages/_region/_locale/daycation.vue' /* webpackChunkName: "pages/_region/_locale/daycation" */))
const _a1b74880 = () => interopDefault(import('../pages/_region/_locale/daytime-hotels.vue' /* webpackChunkName: "pages/_region/_locale/daytime-hotels" */))
const _3cc18ef9 = () => interopDefault(import('../pages/_region/_locale/dayuse-vs-flow.vue' /* webpackChunkName: "pages/_region/_locale/dayuse-vs-flow" */))
const _6901c072 = () => interopDefault(import('../pages/_region/_locale/event-terms.vue' /* webpackChunkName: "pages/_region/_locale/event-terms" */))
const _61767049 = () => interopDefault(import('../pages/_region/_locale/faq.vue' /* webpackChunkName: "pages/_region/_locale/faq" */))
const _fa20b7c8 = () => interopDefault(import('../pages/_region/_locale/favourite.vue' /* webpackChunkName: "pages/_region/_locale/favourite" */))
const _4c83ade4 = () => interopDefault(import('../pages/_region/_locale/hourly-hotels.vue' /* webpackChunkName: "pages/_region/_locale/hourly-hotels" */))
const _c1050f24 = () => interopDefault(import('../pages/_region/_locale/invite-friends.vue' /* webpackChunkName: "pages/_region/_locale/invite-friends" */))
const _1ab2a6fc = () => interopDefault(import('../pages/_region/_locale/order-checkout.vue' /* webpackChunkName: "pages/_region/_locale/order-checkout" */))
const _3d8405b4 = () => interopDefault(import('../pages/_region/_locale/privacy-policy.vue' /* webpackChunkName: "pages/_region/_locale/privacy-policy" */))
const _2858ece0 = () => interopDefault(import('../pages/_region/_locale/promotions/index.vue' /* webpackChunkName: "pages/_region/_locale/promotions/index" */))
const _1c32bc42 = () => interopDefault(import('../pages/_region/_locale/redirection.vue' /* webpackChunkName: "pages/_region/_locale/redirection" */))
const _3d2576b6 = () => interopDefault(import('../pages/_region/_locale/retrieve-booking.vue' /* webpackChunkName: "pages/_region/_locale/retrieve-booking" */))
const _274f0052 = () => interopDefault(import('../pages/_region/_locale/rewards.vue' /* webpackChunkName: "pages/_region/_locale/rewards" */))
const _275264e8 = () => interopDefault(import('../pages/_region/_locale/sitemap.vue' /* webpackChunkName: "pages/_region/_locale/sitemap" */))
const _5924184c = () => interopDefault(import('../pages/_region/_locale/spinner.vue' /* webpackChunkName: "pages/_region/_locale/spinner" */))
const _40aca118 = () => interopDefault(import('../pages/_region/_locale/terms-of-use.vue' /* webpackChunkName: "pages/_region/_locale/terms-of-use" */))
const _a285d8a0 = () => interopDefault(import('../pages/_region/_locale/top-up.vue' /* webpackChunkName: "pages/_region/_locale/top-up" */))
const _e4257180 = () => interopDefault(import('../pages/_region/_locale/work-from-hotel.vue' /* webpackChunkName: "pages/_region/_locale/work-from-hotel" */))
const _c2348274 = () => interopDefault(import('../pages/_region/_locale/user/account.vue' /* webpackChunkName: "pages/_region/_locale/user/account" */))
const _0d915fe7 = () => interopDefault(import('../pages/_region/_locale/user/booking-details.vue' /* webpackChunkName: "pages/_region/_locale/user/booking-details" */))
const _34574470 = () => interopDefault(import('../pages/_region/_locale/user/credit.vue' /* webpackChunkName: "pages/_region/_locale/user/credit" */))
const _153062c1 = () => interopDefault(import('../pages/_region/_locale/user/edit.vue' /* webpackChunkName: "pages/_region/_locale/user/edit" */))
const _79d7aedf = () => interopDefault(import('../pages/_region/_locale/user/inbox.vue' /* webpackChunkName: "pages/_region/_locale/user/inbox" */))
const _373ab7a0 = () => interopDefault(import('../pages/_region/_locale/user/message.vue' /* webpackChunkName: "pages/_region/_locale/user/message" */))
const _21315752 = () => interopDefault(import('../pages/_region/_locale/user/password.vue' /* webpackChunkName: "pages/_region/_locale/user/password" */))
const _2e559b24 = () => interopDefault(import('../pages/_region/_locale/user/past-bookings.vue' /* webpackChunkName: "pages/_region/_locale/user/past-bookings" */))
const _9adbbff8 = () => interopDefault(import('../pages/_region/_locale/user/promo-cards-generator.vue' /* webpackChunkName: "pages/_region/_locale/user/promo-cards-generator" */))
const _f98f0826 = () => interopDefault(import('../pages/_region/_locale/user/reward-history.vue' /* webpackChunkName: "pages/_region/_locale/user/reward-history" */))
const _18f37cb6 = () => interopDefault(import('../pages/_region/_locale/user/saved-cards.vue' /* webpackChunkName: "pages/_region/_locale/user/saved-cards" */))
const _63fc7969 = () => interopDefault(import('../pages/_region/_locale/user/setting.vue' /* webpackChunkName: "pages/_region/_locale/user/setting" */))
const _18329ac7 = () => interopDefault(import('../pages/_region/_locale/user/voucher.vue' /* webpackChunkName: "pages/_region/_locale/user/voucher" */))
const _8c975c14 = () => interopDefault(import('../pages/_region/_locale/blog/author/_id.vue' /* webpackChunkName: "pages/_region/_locale/blog/author/_id" */))
const _31d5d896 = () => interopDefault(import('../pages/_region/_locale/blog/tag/_id.vue' /* webpackChunkName: "pages/_region/_locale/blog/tag/_id" */))
const _72f205ac = () => interopDefault(import('../pages/_region/_locale/blog/_id.vue' /* webpackChunkName: "pages/_region/_locale/blog/_id" */))
const _16d6aff8 = () => interopDefault(import('../pages/_region/_locale/promotions/_id.vue' /* webpackChunkName: "pages/_region/_locale/promotions/_id" */))
const _438ae1b2 = () => interopDefault(import('../pages/_region/_locale/_space/index.vue' /* webpackChunkName: "pages/_region/_locale/_space/index" */))
const _4d66c477 = () => interopDefault(import('../pages/_region/_locale/_spaceName/_space.vue' /* webpackChunkName: "pages/_region/_locale/_spaceName/_space" */))
const _0e847c80 = () => interopDefault(import('../pages/_region/_locale/_space/_location/_keyword.vue' /* webpackChunkName: "pages/_region/_locale/_space/_location/_keyword" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/redirect",
    component: _68847faa,
    name: "redirect"
  }, {
    path: "/spinner",
    component: _f1214044,
    name: "spinner"
  }, {
    path: "/api/reservation/payout/tapngo",
    component: _4212c3a9,
    name: "api-reservation-payout-tapngo"
  }, {
    path: "/",
    component: _1c39d957,
    name: "index"
  }, {
    path: "/api/reservation/payout/:provider?/fail",
    component: _2edc5fd3,
    name: "api-reservation-payout-provider-fail"
  }, {
    path: "/api/reservation/payout/:provider?/success",
    component: _1c2cb2de,
    name: "api-reservation-payout-provider-success"
  }, {
    path: "/api/reservation/payout/:provider?/web/fail",
    component: _6cc2c4a4,
    name: "api-reservation-payout-provider-web-fail"
  }, {
    path: "/api/reservation/payout/:provider?/web/success",
    component: _e36e39ba,
    name: "api-reservation-payout-provider-web-success"
  }, {
    path: "/:region",
    component: _e4626d4a,
    name: "region"
  }, {
    path: "/:region/:locale",
    component: _e2045576,
    name: "region-locale"
  }, {
    path: "/:region/:locale/about",
    component: _6f45fa80,
    name: "region-locale-about"
  }, {
    path: "/:region/:locale/airport-hotels",
    component: _890513c4,
    name: "region-locale-airport-hotels"
  }, {
    path: "/:region/:locale/app",
    component: _7281e1d4,
    name: "region-locale-app"
  }, {
    path: "/:region/:locale/best-price-guarantee",
    component: _1d4661f8,
    name: "region-locale-best-price-guarantee"
  }, {
    path: "/:region/:locale/blog",
    component: _70a55242,
    name: "region-locale-blog"
  }, {
    path: "/:region/:locale/budget-hotels",
    component: _31f1f08c,
    name: "region-locale-budget-hotels"
  }, {
    path: "/:region/:locale/completed",
    component: _ea794e84,
    name: "region-locale-completed"
  }, {
    path: "/:region/:locale/contact",
    component: _8fa3a25a,
    name: "region-locale-contact"
  }, {
    path: "/:region/:locale/day-use-hotels",
    component: _1e7d039a,
    name: "region-locale-day-use-hotels"
  }, {
    path: "/:region/:locale/daycation",
    component: _2386a801,
    name: "region-locale-daycation"
  }, {
    path: "/:region/:locale/daytime-hotels",
    component: _a1b74880,
    name: "region-locale-daytime-hotels"
  }, {
    path: "/:region/:locale/dayuse-vs-flow",
    component: _3cc18ef9,
    name: "region-locale-dayuse-vs-flow"
  }, {
    path: "/:region/:locale/event-terms",
    component: _6901c072,
    name: "region-locale-event-terms"
  }, {
    path: "/:region/:locale/faq",
    component: _61767049,
    name: "region-locale-faq"
  }, {
    path: "/:region/:locale/favourite",
    component: _fa20b7c8,
    name: "region-locale-favourite"
  }, {
    path: "/:region/:locale/hourly-hotels",
    component: _4c83ade4,
    name: "region-locale-hourly-hotels"
  }, {
    path: "/:region/:locale/invite-friends",
    component: _c1050f24,
    name: "region-locale-invite-friends"
  }, {
    path: "/:region/:locale/order-checkout",
    component: _1ab2a6fc,
    name: "region-locale-order-checkout"
  }, {
    path: "/:region/:locale/privacy-policy",
    component: _3d8405b4,
    name: "region-locale-privacy-policy"
  }, {
    path: "/:region/:locale/promotions",
    component: _2858ece0,
    name: "region-locale-promotions"
  }, {
    path: "/:region/:locale/redirection",
    component: _1c32bc42,
    name: "region-locale-redirection"
  }, {
    path: "/:region/:locale/retrieve-booking",
    component: _3d2576b6,
    name: "region-locale-retrieve-booking"
  }, {
    path: "/:region/:locale/rewards",
    component: _274f0052,
    name: "region-locale-rewards"
  }, {
    path: "/:region/:locale/sitemap",
    component: _275264e8,
    name: "region-locale-sitemap"
  }, {
    path: "/:region/:locale/spinner",
    component: _5924184c,
    name: "region-locale-spinner"
  }, {
    path: "/:region/:locale/terms-of-use",
    component: _40aca118,
    name: "region-locale-terms-of-use"
  }, {
    path: "/:region/:locale/top-up",
    component: _a285d8a0,
    name: "region-locale-top-up"
  }, {
    path: "/:region/:locale/work-from-hotel",
    component: _e4257180,
    name: "region-locale-work-from-hotel"
  }, {
    path: "/:region/:locale/user/account",
    component: _c2348274,
    name: "region-locale-user-account"
  }, {
    path: "/:region/:locale/user/booking-details",
    component: _0d915fe7,
    name: "region-locale-user-booking-details"
  }, {
    path: "/:region/:locale/user/credit",
    component: _34574470,
    name: "region-locale-user-credit"
  }, {
    path: "/:region/:locale/user/edit",
    component: _153062c1,
    name: "region-locale-user-edit"
  }, {
    path: "/:region/:locale/user/inbox",
    component: _79d7aedf,
    name: "region-locale-user-inbox"
  }, {
    path: "/:region/:locale/user/message",
    component: _373ab7a0,
    name: "region-locale-user-message"
  }, {
    path: "/:region/:locale/user/password",
    component: _21315752,
    name: "region-locale-user-password"
  }, {
    path: "/:region/:locale/user/past-bookings",
    component: _2e559b24,
    name: "region-locale-user-past-bookings"
  }, {
    path: "/:region/:locale/user/promo-cards-generator",
    component: _9adbbff8,
    name: "region-locale-user-promo-cards-generator"
  }, {
    path: "/:region/:locale/user/reward-history",
    component: _f98f0826,
    name: "region-locale-user-reward-history"
  }, {
    path: "/:region/:locale/user/saved-cards",
    component: _18f37cb6,
    name: "region-locale-user-saved-cards"
  }, {
    path: "/:region/:locale/user/setting",
    component: _63fc7969,
    name: "region-locale-user-setting"
  }, {
    path: "/:region/:locale/user/voucher",
    component: _18329ac7,
    name: "region-locale-user-voucher"
  }, {
    path: "/:region/:locale/blog/author/:id?",
    component: _8c975c14,
    name: "region-locale-blog-author-id"
  }, {
    path: "/:region/:locale/blog/tag/:id?",
    component: _31d5d896,
    name: "region-locale-blog-tag-id"
  }, {
    path: "/:region/:locale/blog/:id",
    component: _72f205ac,
    name: "region-locale-blog-id"
  }, {
    path: "/:region/:locale/promotions/:id",
    component: _16d6aff8,
    name: "region-locale-promotions-id"
  }, {
    path: "/:region/:locale/:space",
    component: _438ae1b2,
    name: "region-locale-space"
  }, {
    path: "/:region/:locale/:spaceName/:space?",
    component: _4d66c477,
    name: "region-locale-spaceName-space"
  }, {
    path: "/:region/:locale/:space/:location/:keyword?",
    component: _0e847c80,
    name: "region-locale-space-location-keyword"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
